import axios from 'axios';
import { addQuestions, setEndReached } from '../store/questions/actions';
import { setAnswers } from '../store/answers/actions';
import { setAds } from '../store/ads/actions';
import { isMiniApp } from './_functions';

// Получить вопросы
export const getQuestions = (sort, offset, count, seed) => {
    const successFunc = (result, dispatch) => {
        if (result.data.result === 'success') {
            dispatch(addQuestions(result.data.questions));
            if (result.data.questions.length === 0 || result.data.questions.length < count) dispatch(setEndReached());
        } else {
            dispatch(setEndReached());
        }
    };

    return postRequest('get-questions', { sort, offset, count, seed }, true, successFunc);
};

// Получить ответы
export const getAnswers = questionId => {
    const successFunc = (result, dispatch) => {
        if (result.data.result === 'success') {
            dispatch(setAnswers(result.data.answers));
        }
    };

    return postRequest('get-answers', { id: questionId }, true, successFunc);
};

// Вопрос расшарен
export const addShareQuestions = qid => {
    return postRequest('share-question', { qid }, true);
};

// Вопрос расшарен
export const saveTurnNotify = isOn => {
    return postRequest('turn-notify', { is_on: isOn }, true);
};

// Создать юзера
export const createUser = () => {
    return postRequest('new-user', {}, true);
};

// Записать хеш
export const saveVisitHash = visitHash => {
    const appType = isMiniApp() ? 'mini-app' : 'direct-game';
    return postRequest('visit-hash', { visit_hash: visitHash, app_type: appType }, true);
};

// Шаблон запроса
export const postRequest = (
    url,
    postData,
    needCheck = false,
    onSuccessCustom = undefined,
    onFailCustom = undefined,
    beforeStartCustom = undefined,
) => {
    return async dispatch => {
        const beforeStart = () => {
            if (beforeStartCustom !== undefined) return beforeStartCustom(dispatch);
        };

        const onSuccess = result => {
            if (onSuccessCustom !== undefined) return onSuccessCustom(result, dispatch);
            else return result.data;
        };

        const onFail = error => {
            if (onFailCustom !== undefined) return onFailCustom(error, dispatch);
            else return error;
        };

        try {
            beforeStart();

            const header = {
                ContentType: 'application/x-www-form-urlencoded',
            };

            let preparedPostData = new FormData();

            if (Object.keys(postData).length > 0) {
                Object.keys(postData).map(key => {
                    preparedPostData.append(key, postData[key]);
                });
            }

            if (needCheck) {
                preparedPostData.append('url', window.location.href);
            }

            const result = await axios.post(process.env.REACT_APP_BACK_URL + '/' + url + '/', preparedPostData, header);

            return onSuccess(result);
        } catch (error) {
            return onFail(error);
        }
    };
};
