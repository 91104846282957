const parseParams = (link = '') => {
    return link.slice(1, link.length).split('&').reduce((a, x) => {
        const data = x.split('=');
        if (['vk_app_id', 'vk_user_id'].includes(data[0])) {
            return {
                ...a,
                [data[0]]: Number(data[1]),
            };
        }

        return {
            ...a,
            [data[0]]: data[1],
        };
    }, {});
};

export default parseParams;
