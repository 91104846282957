import * as types from './actionTypes';
import * as React from 'react';
import { randomIntFromInterval } from '../../services/_functions';

const initialState = {
    answersLoaded: false,
    pictureLoaded: false,
    imageLoading: false,
    defaultImage: false,
    pictureNum: undefined,
    loaderTimeElapsed: false,
    pictureHeight: 0,
    labelHeight: 0,
    imageRect: {
        x1: 0,
        x2: 0,
        y1: 0,
        y2: 0,
    },
    preloader: undefined,
    particles: undefined,
    imagePictureKey: randomIntFromInterval(1, 9999999999),
};

export const omg = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_ANSWERS_LOADED:
            return {
                ...state,
                answersLoaded: true,
            };

        case types.SET_PICTURE_LOADED:
            return {
                ...state,
                pictureLoaded: true,
            };

        case types.SET_LOADER_TIME_ELAPSED:
            return {
                ...state,
                loaderTimeElapsed: true,
            };

        case types.SET_LABEL_HEIGHT:
            return {
                ...state,
                labelHeight: action.height,
            };

        case types.SET_PICTURE_UNLOADED:
            return {
                ...state,
                pictureLoaded: false,
            };

        case types.SET_IMAGE_RECT:
            return {
                ...state,
                imageRect: {
                    x1: action.x1,
                    x2: action.x2,
                    y1: action.y1,
                    y2: action.y2,
                },
                pictureHeight: action.y2 - action.y1,
            };

        case types.SET_PRELOADER:
            return {
                ...state,
                preloader: action.preloader,
            };

        case types.SET_PARTICLES:
            return {
                ...state,
                particles: action.particles,
            };

        case types.RESTART:
            return {
                ...state,
                imagePictureKey: randomIntFromInterval(1, 9999999999),
                pictureLoaded: false,
                loaderTimeElapsed: false,
                answersLoaded: true,
            };

        case types.IMAGE_LOADING:
            return {
                ...state,
                imageLoading: true,
                defaultImage: false,
            };

        case types.DEFAULT_IMAGE_LOADING:
            return {
                ...state,
                imageLoading: true,
                defaultImage: true,
            };

        case types.RESET_PROPS:
            return {
                ...state,
                answersLoaded: false,
                pictureLoaded: false,
                imageLoading: false,
                defaultImage: false,
                loaderTimeElapsed: false,
                particles: undefined,
                preloader: undefined,
                pictureNum: undefined,
            };

        case types.SET_LOADER_TIME_ELAPSED_FALSE:
            return {
                ...state,
                loaderTimeElapsed: false,
            };

        case types.SET_PICTURE_NUM:
            return {
                ...state,
                pictureNum: action.pictureNum,
            };

        default:
            return state;
    }
};
