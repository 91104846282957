import React, { PureComponent } from 'react';
import * as d3 from 'd3';
import { checkUndefined, startMediaAd } from '../services/_functions';
import '../../scss/OmgParticles.scss';

export default class OmgParticles extends PureComponent {
    constructor(props) {
        super(props);

        this.layerRef = React.createRef();

        this.state = {
            canvasSet: false,
            spritesLoaded: [],
            allDone: false,
        };

        this.particles = [];

        this.amount = this.props.amount;
        this.FPS = 100;

        this.lastFrameTime = new Date();
        this.nowFrameTime = new Date();

        this.intervalExist = false;

        this.prepareParams();
    }

    prepareParams() {
        this.spritesImg = checkUndefined(this.props.params, 'sprites', ['1']);
        this.state = { ...this.state, spritesLoaded: new Array(this.spritesImg.length).fill(false) };
    }

    createParticles() {
        this.customCreateParticles();

        if (!this.intervalExist) {
            if (this.animationInterval === undefined) this.animationInterval = d3.timer(this.updateParticles);
            else this.animationInterval.restart(this.updateParticles);
            this.intervalExist = true;
        }
    }

    customCreateParticles() {}

    createParticle = (x, y, speed, angle, rotation, rSpeed, dieSpeed, sprite) => {
        const newParticle = {
            x,
            y,
            speed,
            angle,
            rotation,
            rSpeed,
            dieSpeed,
            sprite,
            life: 100,
        };

        this.particles.push(newParticle);
    };

    updateParticles = () => {
        let multiplier = (this.nowFrameTime - this.lastFrameTime) / (1000 / this.FPS);
        if (multiplier < 1) multiplier = 1;

        let newArray = [];
        for (let i = 0; i < this.particles.length; i++) {
            this.particles[i].x += Math.cos(this.particles[i].angle) * this.particles[i].speed * multiplier;
            this.particles[i].y += Math.sin(this.particles[i].angle) * this.particles[i].speed * multiplier;
            this.particles[i].life -= this.particles[i].dieSpeed * multiplier;
            this.particles[i].rotation += this.particles[i].rSpeed * multiplier;
            this.particles[i].scale = this.particles[i].life / 2;
            if (this.particles[i].life > 85) this.particles[i].scale = 300 - this.particles[i].life * 3;

            if (this.particles[i].life > 0) newArray.push(this.particles[i]);
        }
        this.particles = newArray;
        if (this.particles.length === 0) {
            this.animationInterval.stop();
            this.intervalExist = false;
        }
        this.updateDraw();
    };

    setCanvas() {
        let parent = document.getElementById('canvas-father');
        this.canvas = this.layerRef.current;
        this.canvas.width = parent.offsetWidth;
        this.canvas.height = parent.offsetHeight;
        this.canvasContext = this.canvas.getContext('2d');

        this.sprites = [];

        for (let i = 0; i < this.spritesImg.length; i++) {
            let img = require('../../img/emoji/' + this.spritesImg[i] + '.png');
            let sprite = new Image();
            sprite.src = img;
            sprite.onload = () => this.onLoadImg(i);

            this.sprites.push(sprite);
        }
    }

    onLoadImg = i => {
        this.setState(prevState => {
            let spritesLoaded = [...prevState.spritesLoaded];
            let allDone = prevState.allDone;
            spritesLoaded[i] = true;
            if (!spritesLoaded.includes(false) && allDone === false) {
                allDone = true;
                this.createParticles();
            }

            return {
                ...prevState,
                spritesLoaded: spritesLoaded,
                allDone: allDone,
            };
        });
    };

    drawParticle(particle) {
        let { x, y, rotation, scale, sprite } = particle;
        this.canvasContext.save();
        this.canvasContext.translate(x, y);
        this.canvasContext.rotate(rotation);
        this.canvasContext.translate(-x - scale / 2, -y - scale / 2);
        this.canvasContext.drawImage(this.sprites[sprite], 0, 0, 64, 64, x, y, scale, scale);
        this.canvasContext.restore();
    }

    updateDraw() {
        let particles = this.particles;

        // console.time("drawing");
        this.lastFrameTime = +new Date();
        this.canvasContext.setTransform(1, 0, 0, 1, 0, 0);
        this.canvasContext.clearRect(0, 0, this.canvas.width, this.canvas.height);

        for (let i = 0; i < particles.length; i++) {
            this.drawParticle(particles[i]);
        }
        this.nowFrameTime = +new Date();
        // console.timeEnd("drawing");
    }

    componentDidMount() {
        this.setCanvas();
        this.setState({ canvasSet: true });

        startMediaAd();
    }

    // componentWillUpdate(nextProps, nextState) {
    //     if (nextState.canvasSet) {
    //         this.createParticles();
    //     }
    // }

    clickEvent = e => {};

    render() {
        return (
            <div id="canvas-father" onClick={this.clickEvent}>
                <canvas ref={this.layerRef} />
            </div>
        );
    }
}

OmgParticles.defaultProps = {
    amount: 100,
    params: {},
};
