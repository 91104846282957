import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { setLoaderTimeElapsed, setLoaderTimeElapsedFalse } from '../store/omg/actions';
import '../../scss/OmgLoader.scss';
import '../../scss/OmgIcon.scss';

class OmgLoader extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            dots: '',
            time: 0,
            loaded: false,
            leftPos: 0,
            needCustomPosReset: false,
        };

        this.loaderInterval = setInterval(this.changeDots, 300);
        this.loader = {};
    }

    changeDots = () => {
        this.setState(prevState => {
            let nowState = prevState.dots + '.';
            if (nowState.length > 3) nowState = '';

            let nowLoaded = false;
            let nowTime = prevState.time + 1;

            if ((!this.props.defaultImage && nowTime > 10) || (this.props.defaultImage && nowTime > 50)) {
                nowLoaded = true;
                this.props.dispatch(setLoaderTimeElapsed());
                clearInterval(this.loaderInterval);
            }

            return { dots: nowState, time: nowTime, loaded: nowLoaded };
        });
    };

    componentWillMount() {
        const randomLoadersArr = [
            {
                icon: '⬆️',
                text: 'Смотрите в камеру',
                class: '',
            },
        ];
        if (this.props.preloader !== undefined) {
            this.loader = this.props.preloader;
        } else {
            this.loader = randomLoadersArr[Math.floor(Math.random() * randomLoadersArr.length)];
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.preloader !== undefined) {
            this.loader = nextProps.preloader;
            this.setState({ needCustomPosReset: true });
        }
    }

    componentDidUpdate() {
        if (this.state.needCustomPosReset) {
            this.setState({ needCustomPosReset: false });
            this.resetPos();
        }
    }

    componentDidMount() {
        this.resetPos();
    }

    resetPos() {
        const loader = document.getElementById('omg-loader');
        const picture = document.getElementById('omg-picture');
        this.setState({ leftPos: picture.offsetWidth / 2 - loader.offsetWidth / 2 });
    }

    componentWillUnmount() {
        if (this.props.router === '/') this.props.dispatch(setLoaderTimeElapsedFalse());
        clearInterval(this.loaderInterval);
    }

    render() {
        return (
            <div
                className="omg-loader"
                style={{ top: this.props.labelHeight, left: this.state.leftPos }}
                id="omg-loader"
            >
                <div className={'icon ' + this.loader.class}>{this.loader.icon}</div>
                <div className="text">
                    {this.loader.text}
                    <span>{this.state.dots}</span>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        labelHeight: state.omg.labelHeight,
        pictureLoaded: state.omg.pictureLoaded,
        defaultImage: state.omg.defaultImage,
        router: state.router.location.pathname,
        selectedQuestion: state.questions.selectedQuestion,
        pictureNum: state.omg.pictureNum,
    };
};

export default connect(mapStateToProps)(OmgLoader);
