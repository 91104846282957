import React from 'react';
import { connect } from 'react-redux';
import OmgParticles from '../../classes/OmgParticles';
import { randomIntFromInterval } from '../../services/_functions';

class OmgLines extends OmgParticles {
    customCreateParticles() {
        this.amount = 200;
        const x1 = this.props.imageRect.x1;
        const y1 = this.props.imageRect.y1;
        const x2 = this.props.imageRect.x2;
        const y2 = this.props.imageRect.y2;
        for (let i = 0; i < this.amount; i++) {
            const direction = randomIntFromInterval(1, 4);
            const px = x1 + Math.random() * (x2 - x1) + Math.random() * 20 - 10;
            const py = y1 + Math.random() * (y2 - y1) + Math.random() * 20 - 10;
            const speed = 2;
            const angle = (direction * 90 * Math.PI) / 180;
            const rotation = 0;
            const rSpeed = 0;
            const dieSpeed = (Math.random() * 10 + 5) / 5;
            const sprite = Math.floor(Math.random() * this.sprites.length);
            this.createParticle(px, py, speed, angle, rotation, rSpeed, dieSpeed, sprite);
        }
    }
}

const mapStateToProps = state => {
    return {
        imageRect: state.omg.imageRect,
    };
};

export default connect(mapStateToProps)(OmgLines);
