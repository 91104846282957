import React, { Component } from 'react';
import * as VK from '@vkontakte/vkui';
import { connect } from 'react-redux';
import { IS_GAME_APP_AD_ON, IS_MINI_APP_AD_ON } from '../services/_locals';
import { resetAnswerProps } from '../store/omg/actions';
import { clearAnswers } from '../store/answers/actions';
import { cleanSelectedQuestion, resetQuestions, setGetQuestionsParams, startLoading } from '../store/questions/actions';
import { getQuestions } from '../services/db';
import { getBlockAd } from '../services/vk';
import { isDirectGamesWeb, isMiniApp } from '../services/_functions';
import QuestionsList from '../components/QuestionsList';
import '../../scss/OmgAd.scss';
import '../../scss/PanelDirectGame.scss';

class PanelMain extends Component {
    constructor(props) {
        super(props);

        this.isAdClosed = React.createRef();
        this.state = {
            promoInterval: null,
            promoBannerContent: null,
            needLoadQuestions: 1,
        };
    }

    settings() {
        this.title = 'OMG';
        this.theme = 'gray';
    }

    componentDidMount() {
        if (this.props.router === '/') {
            setTimeout(() => {
                this.props.dispatch(resetAnswerProps());
                this.props.dispatch(clearAnswers());
                this.props.dispatch(cleanSelectedQuestion());
            }, 50);
        }

        if ((isMiniApp() && IS_MINI_APP_AD_ON) || (!isMiniApp() && IS_GAME_APP_AD_ON)) {
            this.showPromoAd();

            this.setState({
                promoInterval: setInterval(() => {
                    if (this.isAdClosed.current === false) {
                        this.showPromoAd();
                    }
                }, 10000),
            });
        }

        if (isDirectGamesWeb()) {
            document.getElementById('panelMain').addEventListener('scroll', this.trackScrolling);
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.promoInterval);

        if (isDirectGamesWeb()) {
            document.getElementById('panelMain').removeEventListener('scroll', this.trackScrolling);
        }
    }

    showPromoAd = async () => {
        const blockBanner = await getBlockAd();
        if (blockBanner.result === 'success') {
            this.setState({ promoBannerContent: blockBanner.data });
        }
    };

    selectType = async type => {
        this.props.dispatch(startLoading());
        const seed = Math.round(Math.random() * 1000000);
        await this.props.dispatch(setGetQuestionsParams(type, 0, 13, seed));
        await this.props.dispatch(resetQuestions());

        this.props.dispatch(
            getQuestions(
                this.props.getQuestionsParams.sort,
                this.props.getQuestionsParams.offset,
                this.props.getQuestionsParams.count,
                this.props.getQuestionsParams.seed,
            ),
        );
    };

    selectRand = () => {
        this.selectType('rand');
    };

    selectNew = async () => {
        this.selectType('new');
    };

    selectPopular = async () => {
        this.selectType('popular');
    };

    closePromo = () => {
        this.setState({ promoBannerContent: null });
        this.isAdClosed.current = true;
    };

    trackScrolling = () => {
        const wrappedElement = document.getElementById('panelMain');
        if (this.isBottom(wrappedElement) && !this.props.isQuestionsLoading && !this.props.endReached) {
            this.setState(prevState => ({
                ...prevState,
                needLoadQuestions: prevState.needLoadQuestions + 1,
            }));
        }
    };

    isBottom(el) {
        const scroll = el.scrollTop;
        const panelHeight = el.clientHeight;
        const height = document.querySelector('.Panel__in').getBoundingClientRect().height;

        return scroll + panelHeight >= height - 50;
    }

    render() {
        return (
            <VK.Panel id={this.props.id} className={isDirectGamesWeb() ? 'direct-game-panel' : ''}>
                <VK.PanelHeader>OMG</VK.PanelHeader>
                <VK.Div style={{ marginBottom: -10, paddingLeft: 10 }}>
                    <VK.Button
                        mode={this.props.getQuestionsParams.sort === 'rand' ? 'primary' : 'outline'}
                        onClick={this.selectRand}
                    >
                        Все
                    </VK.Button>
                    <VK.Button
                        mode={this.props.getQuestionsParams.sort === 'new' ? 'primary' : 'outline'}
                        style={{ marginLeft: 10 }}
                        onClick={this.selectNew}
                    >
                        Новые
                    </VK.Button>
                    <VK.Button
                        mode={this.props.getQuestionsParams.sort === 'popular' ? 'primary' : 'outline'}
                        style={{ marginLeft: 10 }}
                        onClick={this.selectPopular}
                    >
                        Топ
                    </VK.Button>
                </VK.Div>
                <QuestionsList needLoadQuestions={this.state.needLoadQuestions} />
                {this.props.isQuestionsLoading && (
                    <VK.Spinner size="regular" style={{ marginBottom: 20, marginTop: 10 }} />
                )}
                {this.state.promoBannerContent !== null && (
                    <VK.PromoBanner onClose={this.closePromo} bannerData={this.state.promoBannerContent} />
                )}
            </VK.Panel>
        );
    }
}

const mapStateToProps = state => {
    return {
        router: state.router.location.pathname,
        isQuestionsLoading: state.questions.isQuestionsLoading,
        getQuestionsParams: state.questions.getQuestionsParams,
        endReached: state.questions.endReached,
    };
};

export default connect(mapStateToProps)(PanelMain);
