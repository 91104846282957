import React, { Component } from 'react';
import '@vkontakte/vkui/dist/vkui.css';
import { connect } from 'react-redux';
import { IS_ASK_NOTIFY } from './js/services/_locals';
import { goBack } from 'react-router-redux';
import { store } from './index';
import { globalsOpenModal, popHistory, swipeOn } from './js/store/globals/actions';
import { createUser, getAd, getQuestions, saveTurnNotify, saveVisitHash } from './js/services/db';
import { setMockData } from './js/services/mock';
import { setGetQuestionsParams } from './js/store/questions/actions';
import { getAuthToken, getUserInfo, initApp, turnOnNotify } from './js/services/vk';
import * as VK from '@vkontakte/vkui';
import PanelMain from './js/panels/PanelMain';
import PanelOmg from './js/panels/PanelOmg';
import Modals from './js/panels/Modals';
import { getHash, isMiniApp, randomIntFromInterval } from './js/services/_functions';

class App extends Component {
    getViewState = () => {
        if (this.props.pageId !== undefined && this.props.pageId.indexOf('omg_') >= 0) {
            const id = this.props.pageId.split('_')[1];
            return { panel: 'panelOmg', omgId: id };
        }

        switch (this.props.pageId) {
            case '':
                return { panel: 'panelMain' };

            case 'main':
                return { panel: 'panelMain' };

            default:
                return { panel: 'panelMain' };
        }
    };

    async componentWillMount() {
        this.props.dispatch(swipeOn());

        // DEVMOCK
        if (process.env.NODE_ENV === 'development') setMockData();

        // await this.props.dispatch(getUserInfo());

        const seed = Math.round(Math.random() * 1000000);
        await this.props.dispatch(setGetQuestionsParams('rand', 0, 13, seed));

        this.props.dispatch(
            getQuestions(
                this.props.getQuestionsParams.sort,
                this.props.getQuestionsParams.offset,
                this.props.getQuestionsParams.count,
                this.props.getQuestionsParams.seed,
            ),
        );

        const userGetResult = await this.props.dispatch(createUser());

        if (IS_ASK_NOTIFY && isMiniApp() && userGetResult.result === 'success') {
            const timeToAsk = randomIntFromInterval(5000, 10000);
            if (userGetResult.user === undefined || +userGetResult.user.is_push === 0) {
                setTimeout(async () => {
                    this.props.dispatch(globalsOpenModal('notifications'));
                }, timeToAsk);
            }
        }

        if (isMiniApp() && window.location.hash !== undefined && window.location.hash !== '') {
            this.props.dispatch(saveVisitHash(window.location.hash));
        }

        if (!isMiniApp() && getHash() !== '') {
            this.props.dispatch(saveVisitHash(`#${getHash()}`));
        }

        this.props.dispatch(getAuthToken());
    }

    async swipeBack() {
        this.props.dispatch(goBack());
        this.props.dispatch(popHistory());
    }

    render() {
        const panelId = this.getViewState().panel;
        const omgId = this.getViewState().omgId;

        return (
            <VK.ConfigProvider isWebView={true}>
                <VK.Root activeView="mainView">
                    <VK.View
                        id="mainView"
                        activePanel={panelId}
                        history={this.props.history}
                        popout={this.props.popout}
                        onSwipeBack={() => this.swipeBack()}
                        modal={<Modals />}
                    >
                        <PanelMain id="panelMain" />
                        <PanelOmg id="panelOmg" omgId={omgId} key={store.getState().globals.nextActivePanel} />
                    </VK.View>
                </VK.Root>
            </VK.ConfigProvider>
        );
    }
}

function mapStateToProps(state) {
    return {
        history: state.globals.history,
        popout: state.globals.popout,
        swipeStatus: state.globals.swipeStatus,
        getQuestionsParams: state.questions.getQuestionsParams,
    };
}

export default connect(mapStateToProps)(App);
