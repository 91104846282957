import React, { PureComponent } from 'react';
import * as VK from '@vkontakte/vkui';
import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back';
import Icon24Back from '@vkontakte/icons/dist/24/back';
import { goBack } from 'react-router-redux';
import { connect } from 'react-redux';

class BackButton extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            platform: VK.platform() === VK.IOS ? <Icon28ChevronBack /> : <Icon24Back />,
        };
    }

    openBack() {
        this.props.dispatch(goBack());
    }

    render() {
        return <VK.PanelHeaderButton onClick={() => this.openBack()}>{this.state.platform}</VK.PanelHeaderButton>;
    }
}

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps)(BackButton);
