import React from 'react';
import OmgPicture from '../../classes/OmgPicture';
import { connect } from 'react-redux';

class OPLastDefault extends OmgPicture {
    settings() {
        this.bg = {
            bg: 'white-yellow',
            sprites: ['omg-128', 'omg-128-blur-1', 'omg-128-blur-2'],
            amount: 12,
            scaleFrom: 30,
            scaleTo: 100,
            rotationFrom: -20,
            rotationTo: 20,
            sides: false,
            uniform: true,
        };
        this.particles = {
            type: 'explosive',
            sprites: ['omg'],
        };
    }
}

export default connect()(OPLastDefault);
