import * as types from "./actionTypes";

const initialState = {
    questions: [],
    isQuestionsLoading: true,
    selectedQuestion: {},
    getQuestionsParams: {},
    endReached: false,
};

export const questions = (state = initialState, action) => {

    switch (action.type) {

        case types.START_LOADING:
            return {
                ...state,
                isQuestionsLoading: true,
            };

        case types.ADD_QUESTIONS:
            return {
                ...state,
                questions: [...state.questions, ...action.newQuestions],
                isQuestionsLoading: false,
            };

        case types.SET_QUESTIONS:
            return {
                ...state,
                questions: action.questions,
                isQuestionsLoading: false,
            };

        case types.RESET_QUESTIONS:
            return {
                ...state,
                questions: [],
                endReached: false,
            };

        case types.SET_END_REACHED:
            return {
                ...state,
                endReached: true,
                isQuestionsLoading: false,
            };

        case types.SELECT_QUESTION:
            return {
                ...state,
                selectedQuestion: state.questions[action.id],
            };

        case types.CLEAN_SELECTED_QUESTION:
            return {
                ...state,
                selectedQuestion: {},
            };

        case types.SET_GET_QUESTIONS_PARAMS:
            return {
                ...state,
                getQuestionsParams: {
                    sort: action.sort,
                    offset: action.offset,
                    count: action.count,
                    seed: action.seed,
                },
            };

        case types.GET_NEXT_QUESTIONS:
            return {
                ...state,
                getQuestionsParams: {
                    ...state.getQuestionsParams,
                    offset: state.getQuestionsParams.offset + state.getQuestionsParams.count,
                },
            };

        default:
            return state;
    }
};