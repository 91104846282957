import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Question from './Question';
import { getNextQuestions, startLoading } from '../store/questions/actions';
import { getQuestions } from '../services/db';
import { isDirectGamesWeb } from '../services/_functions';

class QuestionsList extends PureComponent {
    state = {
        listenerExist: true,
        endReached: false,
    };

    trackScrolling = () => {
        const wrappedElement = document.getElementById('questions-list');

        if (this.isBottom(wrappedElement) && !this.props.isQuestionsLoading) {
            this.loadItems();
        }
    };

    loadItems = async () => {
        this.props.dispatch(startLoading());
        await this.props.dispatch(getNextQuestions());

        this.props.dispatch(
            getQuestions(
                this.props.getQuestionsParams.sort,
                this.props.getQuestionsParams.offset,
                this.props.getQuestionsParams.count,
                this.props.getQuestionsParams.seed,
            ),
        );
    };

    isBottom(el) {
        return el.getBoundingClientRect().bottom - 50 <= window.innerHeight;
    }

    componentDidMount() {
        document.addEventListener('scroll', this.trackScrolling);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling);
    }

    componentWillUpdate(nextProps, nextState) {
        if (isDirectGamesWeb() && this.props.needLoadQuestions !== nextProps.needLoadQuestions) {
            this.loadItems();
        }

        if (!nextState.listenerExist && !nextProps.endReached) {
            document.addEventListener('scroll', this.trackScrolling);
            this.setState({ listenerExist: true });
        }

        if (nextState.listenerExist && nextProps.endReached) {
            document.removeEventListener('scroll', this.trackScrolling);
            this.setState({ listenerExist: false });
        }
    }

    render() {
        return (
            <div className="questions-list" id="questions-list">
                {this.props.questions.map((question, i) => {
                    if (question.id !== this.props.selectedQuestion.id)
                        return (
                            <Question
                                id={question.id}
                                ptype={question.ptype}
                                num={i}
                                title={question.title}
                                flag={+question.flag}
                                isNew={!!+question.is_new}
                                isPopular={!!+question.is_popular}
                                key={'q' + question.id}
                            />
                        );
                    else return null;
                })}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        questions: state.questions.questions,
        selectedQuestion: state.questions.selectedQuestion,
        isQuestionsLoading: state.questions.isQuestionsLoading,
        getQuestionsParams: state.questions.getQuestionsParams,
        endReached: state.questions.endReached,
    };
};

export default connect(mapStateToProps)(QuestionsList);
