import parseParams from './parseParams';
import schemeChanger from './schemeChanger';
import fireEvent from './fireEvent';
import wordPad from './wordPad';
import binary from './binary';
import loadFile from './loadFile';

export default {
    parseParams,
    schemeChanger,
    fireEvent,
    wordPad,
    binary,
    loadFile,
};
