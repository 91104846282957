import React, { PureComponent } from 'react';
import OmgImg from '../components/OmgImg';
import OmgBg from './OmgBg';
import { setParticles, setPreloader } from '../store/omg/actions';
import '../../scss/OmgPicture.scss';

export default class OmgPicture extends PureComponent {
    constructor(props) {
        super(props);

        this.imagesAmount = 1;
        this.customContent = '';
    }

    settings() {}

    componentWillMount() {
        this.settings();
        if (this.preloader !== undefined) this.props.dispatch(setPreloader(this.preloader));
        if (this.particles !== undefined) this.props.dispatch(setParticles(this.particles));
    }

    render() {
        return (
            <React.Fragment>
                <div
                    key="omg-picture"
                    className={'omg-picture' + (this.props.loaded ? ' loaded' : '')}
                    style={{ minHeight: this.props.pictureHeight }}
                    id="omg-picture"
                >
                    <OmgBg params={this.bg} />
                    <OmgImg key={this.props.imagePictureKey} ptype={this.props.ptype} />
                </div>
                {this.customContent}
            </React.Fragment>
        );
    }
}
