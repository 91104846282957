import * as types from './actionTypes';

export const setAnswers = (answers) => (
    {
        type: types.SET_ANSWERS,
        answers,
    }
);

export const selectAnswer = (id) => (
    {
        type: types.SELECT_ANSWER,
        id,
    }
);

export const clearAnswers = () => (
    {
        type: types.CLEAR_ANSWERS,
    }
);