import React, { Component } from 'react';
import ModalRoot from '@vkontakte/vkui/dist/components/ModalRoot/ModalRoot';
import { globalsCloseModal } from '../store/globals/actions';
import { connect } from 'react-redux';
import ModalNotifications from '../components/ModalNotifications';

class Modals extends Component {
    closeModal = modalId => {
        this.props.dispatch(globalsCloseModal());
    };

    render() {
        return (
            <ModalRoot activeModal={this.props.activeModal} onClose={this.closeModal}>
                <ModalNotifications id="notifications" />
            </ModalRoot>
        );
    }
}

const mapStateToProps = state => {
    return {
        activeModal: state.globals.activeModal,
    };
};

export default connect(mapStateToProps)(Modals);
