import * as types from './actionTypes';

export const startLoading = () => (
    {
        type: types.START_LOADING,
    }
);

export const addQuestions = (newQuestions) => (
    {
        type: types.ADD_QUESTIONS,
        newQuestions,
    }
);

export const setQuestions = (questions) => (
    {
        type: types.SET_QUESTIONS,
        questions,
    }
);

export const resetQuestions = () => (
    {
        type: types.RESET_QUESTIONS,
    }
);

export const setEndReached = () => (
    {
        type: types.SET_END_REACHED,
    }
);

export const selectQuestion = (id) => (
    {
        type: types.SELECT_QUESTION,
        id,
    }
);

export const cleanSelectedQuestion = () => (
    {
        type: types.CLEAN_SELECTED_QUESTION,
    }
);

export const setGetQuestionsParams = (sort, offset, count, seed) => (
    {
        type: types.SET_GET_QUESTIONS_PARAMS,
        sort,
        offset,
        count,
        seed,
    }
);

export const getNextQuestions = () => (
    {
        type: types.GET_NEXT_QUESTIONS,
    }
);