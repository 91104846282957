import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push, replace } from 'react-router-redux';
import { selectQuestion } from '../store/questions/actions';
import { resetAnswerProps } from '../store/omg/actions';
import { clearAnswers } from '../store/answers/actions';
import { pushHistory, setNextActivePanel } from '../store/globals/actions';
import { animateScroll } from 'react-scroll';
import '../../scss/Question.scss';
import { openQuestionsAgain } from '../services/_functions';

class Question extends PureComponent {
    openPage = () => {
        if (this.props.router !== '/') animateScroll.scrollToTop();

        this.props.dispatch(selectQuestion(this.props.num));
        this.props.dispatch(resetAnswerProps());
        this.props.dispatch(clearAnswers());
        this.props.dispatch(setNextActivePanel());
        if (this.props.router.indexOf('/omg_') === -1) {
            this.props.dispatch(pushHistory('panelOmg'));
            this.props.dispatch(push('/omg_' + this.props.id));
        } else this.props.dispatch(replace('/omg_' + this.props.id));
    };

    render() {
        const img = require(`../../img/questions/${this.props.ptype}.jpg`);

        const popularArr = ['🔝Популярное', '💣Бомба', '🔥Огонь', '🔫Пушка', '💎Лучшее'];
        const newArr = ['⚡️Новое'];
        const flagsArr = {
            1: ['💣Бомба', '🔥Огонь', '🔫Пушка', '💎Лучшее'],
        };

        let flag = '';
        if (this.props.flag > 0) {
            flag = flagsArr[this.props.flag][Math.floor(Math.random() * flagsArr[this.props.flag].length)];
        } else if (this.props.isNew) {
            flag = newArr[Math.floor(Math.random() * newArr.length)];
        } else if (this.props.isPopular) {
            flag = popularArr[Math.floor(Math.random() * popularArr.length)];
        }

        return (
            <div className="question" onClick={this.openPage}>
                <div className="bg" style={{ backgroundImage: `url(${img})` }} />
                <div className="title">{this.props.title}</div>
                {flag !== '' ? <div className="flag">{flag}</div> : ''}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        router: state.router.location.pathname,
    };
};

export default connect(mapStateToProps)(Question);
