import React, { Component } from 'react';
import * as VK from '@vkontakte/vkui';
import { connect } from 'react-redux';
import { IS_GAME_APP_AD_ON, IS_MINI_APP_AD_ON } from '../services/_locals';
import { restart, setAnswersLoaded, setPictureNum } from '../store/omg/actions';
import { selectAnswer } from '../store/answers/actions';
import { getBlockAd, shareToHistory } from '../services/vk';
import { addShareQuestions, getAnswers } from '../services/db';
import { isDirectGamesWeb, isMiniApp, weightedRandom } from '../services/_functions';
import Icon24Replay from '@vkontakte/icons/dist/24/replay';
import share from '../modules/share';
import utils from '../modules/utils';
import OmgLoader from '../components/OmgLoader';
import OmgPictureSelector from '../components/OmgPictureSelector';
import OmgParticlesSelector from '../components/OmgParticlesSelector';
import QuestionsList from '../components/QuestionsList';
import BackButton from '../components/BackButton';
import '../../scss/PanelOmg.scss';
import '../../scss/OmgAd.scss';
import '../../scss/PanelDirectGame.scss';

class PanelOmg extends Component {
    constructor(props) {
        super(props);

        this.isAdClosed = React.createRef();
        this.state = {
            leftPos: 0,
            pictureNum: undefined,
            answersLoaded: false,
            promoInterval: null,
            promoBannerContent: null,
            needLoadQuestions: 1,
        };
    }

    restart = (weights = undefined) => {
        let n = 0;
        let selectedAnswersNum = -1;
        while (n < 100) {
            selectedAnswersNum = weightedRandom(weights === undefined ? [...this.props.answersWeights] : weights) + 1;
            if (selectedAnswersNum !== this.props.pictureNum) break;
            n++;
        }
        this.props.dispatch(selectAnswer(selectedAnswersNum - 1));
        this.props.dispatch(setPictureNum(selectedAnswersNum));
        this.props.dispatch(restart());
    };

    restartClick = () => {
        this.restart();
    };

    // postWall = async () => {
    // await this.props.dispatch(randAd());
    // this.props.dispatch(
    //     postWallShareResult(
    //         this.props.selectedQuestion.title,
    //         this.props.selectedAnswer.photo,
    //         this.props.selectedQuestion.id,
    //     ),
    // );
    // };

    async componentWillMount() {
        await this.props.dispatch(getAnswers(this.props.selectedQuestion.id));
        this.props.dispatch(setAnswersLoaded());
    }

    componentDidMount() {
        if ((isMiniApp() && IS_MINI_APP_AD_ON) || (!isMiniApp() && IS_GAME_APP_AD_ON)) {
            this.showPromoAd();

            this.setState({
                promoInterval: setInterval(() => {
                    if (this.isAdClosed.current === false) {
                        this.showPromoAd();
                    }
                }, 10000),
            });
        }

        if (isDirectGamesWeb()) {
            document.getElementById('panelOmg').addEventListener('scroll', this.trackScrolling);
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.promoInterval);

        if (isDirectGamesWeb()) {
            document.getElementById('panelOmg').removeEventListener('scroll', this.trackScrolling);
        }
    }

    showPromoAd = async () => {
        const blockBanner = await getBlockAd();
        if (blockBanner.result === 'success') {
            this.setState({ promoBannerContent: blockBanner.data });
        }
    };

    closePromo = () => {
        this.setState({ promoBannerContent: null });
        this.isAdClosed.current = true;
    };

    componentWillReceiveProps(nextProps) {
        if (!this.props.answersLoaded && nextProps.answersLoaded) this.restart([...nextProps.answersWeights]);
    }

    shareWithFriends = async () => {
        const omgBg = require(`../../img/bg/omg-bg.jpg`);
        const bgUrl = this.props.selectedAnswer.link;
        const photo = await utils.loadFile(bgUrl);

        const question = this.props.selectedQuestion.title;

        let img = new Image();
        img.src = bgUrl;

        img.onload = async () => {
            const newW = 1000;
            const k = newW / img.width;
            const newH = img.height * k;
            const midY = 1920 / 2 - newH / 2;
            const hForText = (1920 / 2 - newH / 2) / 2;

            const story = new share.CanvasBuilder(1080, 1920);
            await story.addImage(omgBg, 0, 0, 1080, 1920);
            await story.addImage(photo, 40, midY, newW, newH);

            const questionParts = question.split(' ');

            const questionPartsJoined = [];
            let tmpStr = '';
            questionParts.forEach((part, i) => {
                if (tmpStr === '') {
                    tmpStr = part;
                } else {
                    tmpStr += ' ' + part;
                }

                if (tmpStr.length > 18 || i === questionParts.length - 1) {
                    questionPartsJoined.push(tmpStr);
                    tmpStr = '';
                }
            });
            for (let i = 0; i < question.length; i++) {}

            questionPartsJoined.forEach((part, i) => {
                story.addText(
                    part,
                    1080 / 2 + 5,
                    100 + i * 100 + hForText / questionPartsJoined.length,
                    '80px Roboto',
                    '#ffffff',
                    'center',
                );
                story.addText(
                    part,
                    1080 / 2,
                    100 + i * 100 + hForText / questionPartsJoined.length,
                    '80px Roboto',
                    '#000000',
                    'center',
                );
            });

            story.addText('Узнай про себя!', 1080 / 2 + 5, 1780, '80px Roboto', '#ffffff', 'center');
            story.addText('Узнай про себя!', 1080 / 2, 1780, '80px Roboto', '#000000', 'center');
            story.addText('⬇️ ⬇️ ⬇️', 1080 / 2, 1890, '80px Roboto', '#000000', 'center');

            story.render(async blob => {
                const scale = 4;
                const render = new share.CanvasBuilder(1080 / scale, 1920 / scale);
                await render.addImage(blob, 0, 0, 1080 / scale, 1920 / scale);
                // render.preview();

                const isSuccessShare = await shareToHistory(blob);

                if (isSuccessShare) {
                    if (isSuccessShare) {
                        this.props.dispatch(addShareQuestions(this.props.selectedQuestion.id));
                    }
                }
            });
        };
    };

    trackScrolling = () => {
        const wrappedElement = document.getElementById('panelOmg');
        if (this.isBottom(wrappedElement) && !this.props.isQuestionsLoading && !this.props.endReached) {
            this.setState(prevState => ({
                ...prevState,
                needLoadQuestions: prevState.needLoadQuestions + 1,
            }));
        }
    };

    isBottom(el) {
        const scroll = el.scrollTop;
        const panelHeight = el.clientHeight;
        const height = document.querySelector('.Panel__in').getBoundingClientRect().height;

        return scroll + panelHeight >= height - 50;
    }

    render() {
        const isLoaded = this.props.answersLoaded && this.props.pictureLoaded && this.props.loaderTimeElapsed;

        return (
            <VK.Panel id={this.props.id} className={isDirectGamesWeb() ? 'direct-game-panel' : ''}>
                <VK.PanelHeader left={<BackButton />}>OMG</VK.PanelHeader>
                <VK.Div className="panel-omg">
                    <div className="tags">{this.props.selectedQuestion.tags}</div>
                    <div className="title">{this.props.selectedQuestion.title}</div>
                    <div className="desc">{this.props.selectedQuestion.desc}</div>
                    <OmgPictureSelector
                        ptype={this.props.selectedQuestion.ptype}
                        pictureHeight={this.props.pictureHeight}
                        imagePictureKey={this.props.imagePictureKey}
                        loaded={isLoaded}
                    />
                    <div className="btn-1">
                        <VK.Button disabled={!isLoaded} size="xl" onClick={this.shareWithFriends}>
                            Поделиться с друзьями
                        </VK.Button>
                    </div>
                    <div className="btn-2">
                        <VK.Button
                            disabled={!isLoaded}
                            size="xl"
                            mode="secondary"
                            before={<Icon24Replay />}
                            onClick={this.restartClick}
                        >
                            Еще раз
                        </VK.Button>
                    </div>
                    {!isLoaded && (
                        <OmgLoader
                            preloader={this.props.preloader}
                            type={this.props.selectedQuestion.ptype}
                            setLoadedFunc={this.setLoadedFunc}
                        />
                    )}
                    {isLoaded && <OmgParticlesSelector particles={this.props.particles} />}
                </VK.Div>
                <VK.Div className="also">Вам также может понравиться ⬇️</VK.Div>
                <QuestionsList needLoadQuestions={this.state.needLoadQuestions} />
                {this.state.promoBannerContent !== null && (
                    <VK.PromoBanner onClose={this.closePromo} bannerData={this.state.promoBannerContent} />
                )}
            </VK.Panel>
        );
    }
}

const mapStateToProps = state => {
    return {
        accessToken: state.vk.accessToken,
        selectedQuestion: state.questions.selectedQuestion,
        selectedAnswer: state.answers.selectedAnswer,
        answersWeights: state.answers.answersWeights,
        answersLoaded: state.omg.answersLoaded,
        pictureLoaded: state.omg.pictureLoaded,
        preloader: state.omg.preloader,
        particles: state.omg.particles,
        loaderTimeElapsed: state.omg.loaderTimeElapsed,
        imagePictureKey: state.omg.imagePictureKey,
        pictureHeight: state.omg.pictureHeight,
        pictureNum: state.omg.pictureNum,
        selectedAd: state.ads.selectedAd,
        endReached: state.questions.endReached,
        isQuestionsLoading: state.questions.isQuestionsLoading,
    };
};

export default connect(mapStateToProps)(PanelOmg);
