import * as types from './actionTypes';

export const setNextActivePanel = () => ({
    type: types.SET_NEXT_ACTIVE_PANEL,
});

export const setPopoutSpinner = () => ({
    type: types.SET_POPOUT_SPINNER,
});

export const closePopout = () => ({
    type: types.CLOSE_POPOUT,
});

export const pushHistory = panelId => ({
    type: types.PUSH_HISTORY,
    panelId,
});

export const popHistory = () => ({
    type: types.POP_HISTORY,
});

export const setHistory = history => ({
    type: types.SET_HISTORY,
    history,
});

export const clearHistory = () => ({
    type: types.CLEAR_HISTORY,
});

export const swipeOn = () => ({
    type: types.SWIPE_ON,
});

export const swipeOff = () => ({
    type: types.SWIPE_OFF,
});

export const setPopout = popout => ({
    type: types.SET_POPOUT,
    popout,
});

export const globalsOpenModal = activeModal => ({
    type: types.GLOBALS_OPEN_MODAL,
    activeModal,
});

export const globalsCloseModal = () => ({
    type: types.GLOBALS_CLOSE_MODAL,
});
