import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import { vkReducer } from './vk/reducers';
import { globals } from './globals/reducers';
import { logger } from './logger/reducers';
import { questions } from './questions/reducers';
import { answers } from './answers/reducers';
import { omg } from './omg/reducers';
import { ads } from './ads/reducers';

export const rootReducer = combineReducers({
    router: routerReducer,
    vk: vkReducer,
    ads: ads,
    globals: globals,
    omg: omg,
    logger: logger,
    questions: questions,
    answers: answers,
});
