import * as types from './actionTypes';
import { randomIntFromInterval } from '../../services/_functions';

const initialState = {
    ads: [],
    selectedAd: null,
};

export const ads = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_ADS:
            return {
                ...state,
                ads: action.ads,
            };

        case types.RAND_ADS:
            let selected = null;
            if (state.ads.length > 0) {
                selected = state.ads[randomIntFromInterval(0, state.ads.length - 1)];
            }
            return {
                ...state,
                selectedAd: selected,
            };

        default:
            return state;
    }
};
