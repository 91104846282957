export const SET_NEXT_ACTIVE_PANEL = 'SET_NEXT_ACTIVE_PANEL';
export const SET_CATEGORY_TITLE = 'SET_CATEGORY_TITLE';
export const SET_POPOUT_SPINNER = 'SET_POPOUT_SPINNER';
export const CLOSE_POPOUT = 'CLOSE_POPOUT';
export const PUSH_HISTORY = 'PUSH_HISTORY';
export const POP_HISTORY = 'POP_HISTORY';
export const SET_HISTORY = 'SET_HISTORY';
export const CLEAR_HISTORY = 'CLEAR_HISTORY';
export const SWIPE_ON = 'SWIPE_ON';
export const SWIPE_OFF = 'SWIPE_OFF';
export const SET_POPOUT = 'SET_POPOUT';
export const GLOBALS_OPEN_MODAL = 'GLOBALS_OPEN_MODAL';
export const GLOBALS_CLOSE_MODAL = 'GLOBALS_CLOSE_MODAL';
