import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { Route } from 'react-router';
import { applyMiddleware, createStore } from 'redux';
import { createHashHistory } from 'history';
import { rootReducer } from './js/store/reducers';
import { ConnectedRouter, routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import { initApp } from './js/services/vk';
import { setHistory } from './js/store/globals/actions';

const history = createHashHistory({
    hashType: 'noslash',
});

const logger = store => next => action => {
    if (process.env.REACT_APP_PROD_STATUS === 'development') {
        console.log('dispatching', action);
    }
    return next(action);
};

export const store = createStore(rootReducer, applyMiddleware(thunk, routerMiddleware(history), logger));

history.listen((location, action) => {
    if (location.pathname === '/main' || location.pathname === '/' || location.pathname === '') {
        store.dispatch(setHistory(['panelMain']));
    }

    if (action === 'POP') {
        history.replace('/');
    }
});

store.dispatch(initApp());

const urls = ['', 'main', 'omg_\\d+'];
const pathRegex = '/:pageId(' + urls.join('|') + '|)?';

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <div>
                <Route path={pathRegex} component={props => <App pageId={props.match.params.pageId} />} />
            </div>
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root'),
);
