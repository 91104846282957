import * as types from "./actionTypes";

const initialState = {
    answers: [],
    answersWeights: [],
    isAnswersLoading: true,
    selectedAnswer: {},
};

export const answers = (state = initialState, action) => {

    switch (action.type) {

        case types.SET_ANSWERS:
            const answersTotalWeight = action.answers.map(item => item.priority).reduce((prev, next) => +prev + +next);
            let answersWeights = [];
            action.answers.forEach(answer => {
                answersWeights.push(+answer.priority / answersTotalWeight);
            });

            return {
                ...state,
                answers: action.answers,
                answersWeights: answersWeights,
                isAnswersLoading: false,
            };

        case types.SELECT_ANSWER:
            return {
                ...state,
                selectedAnswer: state.answers[action.id],
            };

        case types.CLEAR_ANSWERS:
            return {
                ...state,
                answers: [],
                answersWeights: [],
                isAnswersLoading: true,
                selectedAnswer: {},
            };

        default:
            return state;
    }
};