import * as types from './actionTypes';

export const setAnswersLoaded = () => (
    {
        type: types.SET_ANSWERS_LOADED,
    }
);

export const setPictureLoaded = () => (
    {
        type: types.SET_PICTURE_LOADED,
    }
);

export const setLoaderTimeElapsed = () => (
    {
        type: types.SET_LOADER_TIME_ELAPSED,
    }
);

export const setLabelHeight = (height) => (
    {
        type: types.SET_LABEL_HEIGHT,
        height,
    }
);

export const setPictureUnloaded = () => (
    {
        type: types.SET_PICTURE_UNLOADED,
    }
);

export const setImageRect = (x1, y1, x2, y2) => (
    {
        type: types.SET_IMAGE_RECT,
        x1,
        x2,
        y1,
        y2,
    }
);

export const setPreloader = (preloader) => (
    {
        type: types.SET_PRELOADER,
        preloader,
    }
);

export const setParticles = (particles) => (
    {
        type: types.SET_PARTICLES,
        particles,
    }
);

export const restart = () => (
    {
        type: types.RESTART,
    }
);

export const setImageLoading = () => (
    {
        type: types.IMAGE_LOADING,
    }
);

export const setDefaultImageLoading = () => (
    {
        type: types.DEFAULT_IMAGE_LOADING,
    }
);

export const resetAnswerProps = () => (
    {
        type: types.RESET_PROPS,
    }
);

export const setLoaderTimeElapsedFalse = () => (
    {
        type: types.SET_LOADER_TIME_ELAPSED_FALSE,
    }
);

export const setPictureNum = (pictureNum) => (
    {
        type: types.SET_PICTURE_NUM,
        pictureNum,
    }
);