import React from 'react';
import OmgPicture from '../../classes/OmgPicture';
import { connect } from 'react-redux';

class OPFindPrince extends OmgPicture {
    settings() {
        this.bg = {
            bg: 'white-yellow',
            sprites: ['crown-128', 'crown-128-blur-1', 'crown-128-blur-2'],
            amount: 12,
            scaleFrom: 30,
            scaleTo: 100,
            rotationFrom: -20,
            rotationTo: 20,
            sides: false,
            uniform: true,
        };
        this.particles = {
            type: 'explosion',
            sprites: ['heart-red', 'heart-orange', 'heart-purple'],
        };
    }
}

export default connect()(OPFindPrince);
