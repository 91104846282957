import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
    setDefaultImageLoading,
    setImageLoading,
    setImageRect,
    setLabelHeight,
    setPictureLoaded,
} from '../store/omg/actions';

class OmgImg extends PureComponent {
    onImgLoad = ({ target: img }) => {
        const picture = document.getElementById('omg-picture');
        this.props.dispatch(setLabelHeight(picture.offsetTop + img.height / 2 - 16));
        this.props.dispatch(
            setImageRect(
                picture.offsetLeft,
                picture.offsetTop,
                picture.offsetLeft + img.width,
                picture.offsetTop + img.height,
            ),
        );
        this.props.dispatch(setPictureLoaded());
    };

    componentWillMount() {
        if (this.props.selectedAnswer.link !== undefined) {
            this.props.dispatch(setImageLoading());
            this.img = this.props.selectedAnswer.link;
        } else {
            this.props.dispatch(setDefaultImageLoading());
            this.img = require('../../img/answers/_default.jpg');
        }
    }

    render() {
        return <img onLoad={this.onImgLoad} src={this.img} />;
    }
}

const mapStateToProps = state => {
    return {
        pictureNum: state.omg.pictureNum,
        selectedAnswer: state.answers.selectedAnswer,
    };
};

export default connect(mapStateToProps)(OmgImg);
