class CanvasBuilder {
    constructor(width = 1080, height = 1920) {
        this.width = width;
        this.height = height;

        this.canvas = document.createElement('canvas');
        this.ctx = this.canvas.getContext('2d');

        this.canvas.width = this.width;
        this.canvas.height = this.height;
    }

    addText(value, x, y, font = 'Arial', color = 'black', textAlign = 'center') {
        this.ctx.beginPath();
        this.ctx.font = font;
        this.ctx.textAlign = textAlign;
        this.ctx.fillStyle = color;
        this.ctx.fillText(value, x, y);
        this.ctx.closePath();
        return this;
    }

    addRect(x, y, width, height, borderStyle = 'black', lineWidth = 1, fillStyle = null) {
        if (borderStyle) {
            this.ctx.beginPath();
            this.ctx.lineWidth = lineWidth;
            this.ctx.strokeStyle = borderStyle;
            this.ctx.rect(x, y, width, height);
            this.ctx.stroke();
            this.ctx.closePath();
        } else {
            this.ctx.strokeStyle = 'none';
        }

        if (fillStyle) {
            this.ctx.fillStyle = fillStyle;
            this.ctx.beginPath();
            this.ctx.fillRect(x, y, width, height);
            this.ctx.closePath();
        }

        return this;
    }

    addArc(x, y, radius, borderStyle = 'black', lineWidth = 1, fillStyle = null, startAngle = 0, endAngle = 2 * Math.PI) {
        if (borderStyle) {
            this.ctx.beginPath();
            this.ctx.lineWidth = lineWidth;
            this.ctx.strokeStyle = borderStyle;
            this.ctx.arc(x, y, radius, startAngle, endAngle);
            this.ctx.stroke();
            this.ctx.closePath();
        } else {
            this.ctx.strokeStyle = 'none';
        }

        if (fillStyle) {
            this.ctx.fillStyle = fillStyle;
            this.ctx.beginPath();
            this.ctx.arc(x, y, radius, startAngle, endAngle);
            this.ctx.closePath();
            this.ctx.fill();
        }

        return this;
    }

    async addImage(imageUrl, x = 0, y = 0, width = 0, height = 0, centered = false, scaler = null) {
        await new Promise((resolve) => {
            const img = new Image;

            img.onload = () => {
                if (width !== 0 && height !== 0) {
                    this.ctx.drawImage(img, x, y, width, height);
                } else {
                    let imgWidth = img.width;
                    let imgHeight = img.height;
                    let calculatedX = x;
                    let calculatedY = y;

                    if (scaler !== null) {
                        const scale = scaler({
                            cw: this.width,
                            ch: this.height,
                            iw: imgWidth,
                            ih: imgHeight,
                        });
                        imgWidth = imgWidth * scale;
                        imgHeight = imgHeight * scale;
                    }

                    if (centered) {
                        calculatedX = this.width / 2 - imgWidth / 2;
                        calculatedY = this.height / 2 - imgHeight / 2;
                    }

                    this.ctx.drawImage(img, calculatedX, calculatedY, imgWidth, imgHeight);
                }

                resolve();
            };

            img.src = imageUrl;
        });

        return this;
    }

    render(callback) {
        if (!callback) {
            return this.canvas.toDataURL('image/png');
        } else {
            callback(this.canvas.toDataURL('image/png'));
        }
    }

    preview() {
        const style = [
            'font-size: 1px',
            'line-height: ' + this.height + 'px',
            'padding: ' + this.height * .5 + 'px ' + this.width * .5 + 'px',
            'background-size: ' + this.width + 'px ' + this.height + 'px',
            'background: url(' + this.render() + ')',
        ].join(';');

        console.log('%c ', style);
        return this;
    }
}

export default CanvasBuilder;
