export const SET_ANSWERS_LOADED             = 'SET_ANSWERS_LOADED';
export const SET_PICTURE_LOADED             = 'SET_PICTURE_LOADED';
export const SET_LOADER_TIME_ELAPSED        = 'SET_LOADER_TIME_ELAPSED';
export const SET_LABEL_HEIGHT               = 'SET_LABEL_HEIGHT';
export const SET_PICTURE_UNLOADED           = 'SET_PICTURE_UNLOADED';
export const SET_IMAGE_RECT                 = 'SET_IMAGE_RECT';
export const SET_PRELOADER                  = 'SET_PRELOADER';
export const SET_PARTICLES                  = 'SET_PARTICLES';
export const RESTART                        = 'RESTART';
export const IMAGE_LOADING                  = 'IMAGE_LOADING';
export const DEFAULT_IMAGE_LOADING          = 'DEFAULT_IMAGE_LOADING';
export const RESET_PROPS                    = 'RESET_PROPS';
export const SET_LOADER_TIME_ELAPSED_FALSE  = 'SET_LOADER_TIME_ELAPSED_FALSE';
export const SET_PICTURE_NUM                = 'SET_PICTURE_NUM';