import React, { PureComponent } from 'react';
import OPDefault from './OmgPictures/OPDefault';
import OPFindPrince from './OmgPictures/OPFindPrince';

let SelectedPicture;

export default class OmgPictureSelector extends PureComponent {
    SwitchPictureType = () => {
        switch (this.props.ptype) {
            case 'prince':
                return OPFindPrince;
            default:
                return OPDefault;
        }
    };

    componentWillMount() {
        SelectedPicture = React.memo(this.SwitchPictureType());
    }

    render() {
        return (
            <SelectedPicture
                key="omg-picture-selected"
                loaded={this.props.loaded}
                pictureHeight={this.props.pictureHeight}
                ptype={this.props.ptype}
                imagePictureKey={this.props.imagePictureKey}
            />
        );
    }
}
