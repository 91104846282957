import * as types from './actionTypes';
import * as React from 'react';
import * as VK from '@vkontakte/vkui';

const initialState = {
    activeModal: null,
    selectedCategoryTitle: '',
    popout: '',
    swipeStatus: true,
    nextActivePanel: Math.round(Math.random() * 1000000000),
    history: ['panelMain'],
};

export const globals = (state = initialState, action) => {
    switch (action.type) {
        case types.GLOBALS_OPEN_MODAL: {
            return {
                ...state,
                activeModal: action.activeModal,
            };
        }

        case types.GLOBALS_CLOSE_MODAL: {
            return {
                ...state,
                activeModal: null,
            };
        }

        case types.SET_NEXT_ACTIVE_PANEL:
            return {
                ...state,
                nextActivePanel: Math.round(Math.random() * 1000000000),
            };

        case types.SET_CATEGORY_TITLE:
            return {
                ...state,
                selectedCategoryTitle: action.selectedCategoryTitle,
            };

        case types.SET_POPOUT:
            return {
                ...state,
                popout: action.popout,
            };

        case types.SET_POPOUT_SPINNER:
            let popout = <VK.ScreenSpinner />;

            return {
                ...state,
                popout: popout,
            };

        case types.CLOSE_POPOUT:
            return {
                ...state,
                popout: '',
            };

        case types.PUSH_HISTORY:
            let historyPush = [...state.history];
            if (historyPush.indexOf(action.panelId) === -1) historyPush.push(action.panelId);
            return {
                ...state,
                history: historyPush,
            };

        case types.POP_HISTORY:
            let historyPop = [...state.history];
            historyPop.pop();
            return {
                ...state,
                history: historyPop,
            };

        case types.SET_HISTORY:
            return {
                ...state,
                history: action.history,
            };

        case types.CLEAR_HISTORY:
            return {
                ...state,
                history: [],
            };

        case types.SWIPE_ON:
            return {
                ...state,
                swipeStatus: true,
            };

        case types.SWIPE_OFF:
            return {
                ...state,
                swipeStatus: false,
            };

        default:
            return state;
    }
};
