import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { checkUndefined, randomIntFromInterval } from '../services/_functions';
import '../../scss/OmgBg.scss';

class OmgBg extends PureComponent {
    constructor(props) {
        super(props);

        this.layerRef = React.createRef();

        this.state = {
            spritesLoaded: [],
            allDone: false,
            bg: undefined,
        };

        this.particles = [];

        this.prepareParams();
        this.generateBgImage();
    }

    prepareParams() {
        this.amount = checkUndefined(this.props.params, 'amount', 50);
        this.spritesImg = checkUndefined(this.props.params, 'sprites', ['1']);
        this.scaleFrom = checkUndefined(this.props.params, 'scaleFrom', 50);
        this.scaleTo = checkUndefined(this.props.params, 'scaleTo', 100);
        this.rotationFrom = checkUndefined(this.props.params, 'rotationFrom', 0);
        this.rotationTo = checkUndefined(this.props.params, 'rotationTo', 360);
        this.sides = checkUndefined(this.props.params, 'sides', true);
        this.uniform = checkUndefined(this.props.params, 'uniform', false);
        this.smooth = checkUndefined(this.props.params, 'smooth', false);
        this.bg = checkUndefined(this.props.params, 'bg', false);
        this.state = { ...this.state, spritesLoaded: new Array(this.spritesImg.length).fill(false) };
    }

    generateBgImage() {
        if (!this.bg) {
            const num = randomIntFromInterval(1, 10);
            this.bgImage = require('../../img/bg/' + num + '.jpg');
        } else {
            this.bgImage = require('../../img/bg/' + this.bg + '.jpg');
        }
    }

    createParticle = (x, y, rotation, scale, sprite) => {
        const newParticle = {
            x,
            y,
            rotation,
            scale,
            sprite,
        };

        this.particles.push(newParticle);
    };

    createBg() {
        for (let i = 0; i < this.amount; i++) {
            let px, py;
            if (this.sides) {
                const side = Math.ceil(Math.random() * 4); // 1 - top, 2 - right, 3 - bottom, 4 - left
                if (side === 1) {
                    px = Math.random() * this.canvas.width;
                    py = Math.random() * 50 - 20;
                } else if (side === 2) {
                    px = Math.random() * 50 - 20 + this.canvas.width;
                    py = Math.random() * this.canvas.height;
                } else if (side === 3) {
                    px = Math.random() * 50 - 20;
                    py = Math.random() * this.canvas.height;
                } else if (side === 4) {
                    px = Math.random() * this.canvas.width;
                    py = Math.random() * 50 - 20 + this.canvas.height;
                }
            } else {
                if (this.uniform) {
                    const max = Math.sqrt(this.amount);
                    const row = Math.floor(i / max) + 1;
                    const column = Math.floor(i - (row - 1) * max) + 1;
                    const range = this.canvas.width / max;
                    px = randomIntFromInterval(
                        row * range - range / 2 - range / 3,
                        row * range - range / 2 + range / 3,
                    );
                    py = randomIntFromInterval(
                        column * range - range / 2 - range / 3,
                        column * range - range / 2 + range / 3,
                    );
                } else {
                    px = Math.random() * this.canvas.width;
                    py = Math.random() * this.canvas.height;
                }
            }

            let rotation = (randomIntFromInterval(this.rotationFrom, this.rotationTo) * Math.PI) / 180;
            let scale = randomIntFromInterval(this.scaleFrom, this.scaleTo);
            let sprite = Math.floor(Math.random() * this.sprites.length);

            this.createParticle(px, py, rotation, scale, sprite);
        }
    }

    setCanvas = () => {
        let parent = document.getElementById('canvas-bg-father');
        this.canvas = this.layerRef.current;
        this.canvas.width = parent.offsetWidth;
        this.canvas.height = parent.offsetHeight;

        this.canvasContext = this.canvas.getContext('2d');

        this.sprites = [];

        for (let i = 0; i < this.spritesImg.length; i++) {
            let img = require('../../img/emoji/' + this.spritesImg[i] + '.png');
            let sprite = new Image();
            sprite.src = img;
            sprite.onload = () => this.onLoadImg(i);

            this.sprites.push(sprite);
        }
    };

    onLoadImg = i => {
        this.setState(prevState => {
            let spritesLoaded = [...prevState.spritesLoaded];
            let allDone = prevState.allDone;
            spritesLoaded[i] = true;
            if (!spritesLoaded.includes(false) && allDone === false) {
                allDone = true;
                this.createBg();
            }

            return {
                ...prevState,
                spritesLoaded: spritesLoaded,
                allDone: allDone,
            };
        });
    };

    drawParticle(particle) {
        let { x, y, rotation, scale, sprite } = particle;
        this.canvasContext.save();
        this.canvasContext.translate(x, y);
        this.canvasContext.rotate(rotation);
        this.canvasContext.translate(-x - scale / 2, -y - scale / 2);
        this.canvasContext.drawImage(this.sprites[sprite], 0, 0, 128, 128, x, y, scale, scale);
        this.canvasContext.restore();
    }

    setBg() {
        for (let i = 0; i < this.particles.length; i++) {
            this.drawParticle(this.particles[i]);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.pictureLoaded) {
            setTimeout(this.setCanvas, 0);
        }
    }

    render() {
        if (this.state.allDone) this.setBg();
        return (
            <div id="canvas-bg-father" style={{ backgroundImage: 'url(' + this.bgImage + ')' }}>
                <canvas key="bg-canvas" ref={this.layerRef} />
            </div>
        );
    }
}

OmgBg.defaultProps = {
    params: {},
};

const mapStateToProps = state => {
    return {
        pictureLoaded: state.omg.pictureLoaded,
    };
};

export default connect(mapStateToProps)(OmgBg);
