import React, { PureComponent } from 'react';
import OmgExplosion from './OmgParticles/OmgExplosion';
import OmgFall from './OmgParticles/OmgFall';
import OmgLines from './OmgParticles/OmgLines';

let SelectedParticles;

export default class OmgParticlesSelector extends PureComponent {
    SwitchPictureType = () => {
        switch (this.props.particles['type']) {
            case 'fall':
                return OmgFall;
            case 'lines':
                return OmgLines;
            default:
                return OmgExplosion;
        }
    };

    componentWillMount() {
        SelectedParticles = React.memo(this.SwitchPictureType());
    }

    render() {
        return <SelectedParticles params={this.props.particles} />;
    }
}

OmgParticlesSelector.defaultProps = {
    particles: {},
};
