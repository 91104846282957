import React, { Component } from 'react';
import { globalsCloseModal } from '../store/globals/actions';
import ModalCard from '@vkontakte/vkui/dist/components/ModalCard/ModalCard';
import Avatar from '@vkontakte/vkui/dist/components/Avatar/Avatar';
import { connect } from 'react-redux';
import omgAva from '../../img/omg-ava.jpg';
import { turnOnNotify } from '../services/vk';
import { saveTurnNotify } from '../services/db';

class ModalNotifications extends Component {
    allowNotify = async () => {
        this.props.dispatch(globalsCloseModal());
        const result = await turnOnNotify();
        if (result.result === 'success') {
            this.props.dispatch(saveTurnNotify(true));
        } else {
            this.props.dispatch(saveTurnNotify(false));
        }
    };

    render() {
        return (
            <ModalCard
                id={this.props.id}
                onClose={() => this.props.dispatch(globalsCloseModal())}
                icon={<Avatar mode="app" src={omgAva} size={72} />}
                header="Подписаться на уведомления от «OMG»?"
                caption="Мы будем уведомлять вас о появлении новых тестов, интересных событий и розыгрышей."
                actions={[
                    {
                        title: 'Подписаться',
                        mode: 'primary',
                        action: () => {
                            this.allowNotify();
                        },
                    },
                ]}
            />
        );
    }
}

const mapStateToProps = state => {
    return {};
};

export default connect(mapStateToProps)(ModalNotifications);
